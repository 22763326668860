import React from 'react';
import styled from 'styled-components';
import GoldenButton from 'components/buttons/GoldenButton';

const Container = styled.div`
    background: linear-gradient(315deg, #fafafa, #d8d7d8);
    border-radius: 8px;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 256px);
    padding: 60px 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        margin-left: 8px;
        margin-right: 8px;
        width: calc(100% - 64px);
        flex-direction: column;
        padding: 32px 24px;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    text-align: left;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: #19171a;
    max-width: 832px;
    user-select: none;
    @media screen and (max-width: 1024px) {
        font-size: 24px;
    }
    @media screen and (max-width: 767px) {
        font-size: 24px;
        text-align: center;
        width: 100%;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    min-width: 155px;
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 767px) {
        flex-direction: row;
        margin-top: 40px;
    }
`;
export default function CTAComponent({
    title = `Reach real people, make real connections,
and drive real brand conversations.`,
    button_title = 'REQUEST DEMO',
}) {
    return (
        <Container>
            <Heading>{title}</Heading>
            <ButtonContainer>
                <GoldenButton type="request-demo">{button_title}</GoldenButton>
            </ButtonContainer>
        </Container>
    );
}
